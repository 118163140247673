@tailwind base;
@tailwind components;
@tailwind utilities;

/* Customização da barra de rolagem */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #2c1418;
}

::-webkit-scrollbar-thumb {
  background: #843742;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #943742;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fire-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(255,68,0,0.1), transparent);
  mix-blend-mode: screen;
}

.fire-overlay-2 {
  background: linear-gradient(-45deg, rgba(255,140,0,0.1), transparent);
}

.fire-overlay-3 {
  background: linear-gradient(180deg, rgba(255,69,0,0.1), transparent);
}

@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse-slow {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.animate-fade-in-down {
  animation: fade-in-down 1s ease-out;
}

.animate-fade-in-up {
  animation: fade-in-up 1s ease-out;
}

.animate-fade-in {
  animation: fade-in 1s ease-out;
}

.animate-pulse-slow {
  animation: pulse-slow 3s infinite;
}

.delay-75 {
  animation-delay: 75ms;
}

.delay-150 {
  animation-delay: 150ms;
}
